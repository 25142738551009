//@ts-nocheck
import IconChart from "../icons/IconChart";
import IconInformation from "../icons/IconInformation";
import { useTheme } from "@mui/material/styles";
import { memo, useContext } from "react";
import {
  Typography,
  Grid,
  Paper,
  Box,
  useMediaQuery,
  Skeleton,
} from "@mui/material";
import { numberWithCommas } from "../../../Common/formatNumber";
import UserContext from "userContext";
import "../style.css";

interface overViewProps {
  carvalue: number | null;
  isLoading: boolean;
  applicationPriceRange: { minPrice: number | null; maxPrice: number | null };
  listedPrice: number | null;
  listedPriceRange: {
    minListedPrice: number | null;
    maxListedPrice: number | null;
  };
}

function OverView({
  carvalue,
  isLoading,
  applicationPriceRange,
  listedPrice,
  listedPriceRange,
}: overViewProps) {
  const isTabletOrMobile = useMediaQuery("(max-width: 1238px)");
  const reponsiveWrapText = useMediaQuery("(max-width: 1325px)");
  const theme = useTheme();
  const { user } = useContext(UserContext);
  const type = localStorage.getItem("type");

  const jsondata = window._env_;
  const { userHidden } = jsondata;

  const isSmallScreen = useMediaQuery("(max-width: 1150px)");

  return (
    <Paper
      sx={{
        borderRadius: "16px",
        mt: "20px",
        p: "16px",
        boxShadow: "none",
        backgroundImage: "unset",
        backgroundColor: theme.palette.mode === "dark" ? "#1111" : "#FFF",
      }}
    >
      <Grid container spacing={2}>
        <Grid
          item
          md={type !== "car" ? 12 : 4.8}
          lg={type !== "car" ? 12 : 4.8}
          sm={type !== "car" ? 12 : 4.8}
          xs={12}
        >
          <Paper
            sx={{
              background:
                "linear-gradient(42deg, #6073FF 29.07%, #B1BBFF 86.87%)",
              borderRadius: "16px",
              paddingX: isSmallScreen
                ? "5px"
                : reponsiveWrapText
                ? "10px"
                : "30px",
              paddingY: "30px",
              boxShadow: "none",
            }}
          >
            <Paper
              sx={{
                backgroundColor: "white",
                width: "36px",
                height: "36px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "8px",
                boxShadow: "none",
              }}
            >
              <IconChart />
            </Paper>
            <Typography
              sx={{ color: "#FFF", mt: 3 }}
              variant={isTabletOrMobile ? "h5" : "h3"}
            >
              Giá trị xe
            </Typography>
            <Box className="display-space" sx={{ mt: 3 }}>
              <Typography
                sx={{ color: "#FFF" }}
                variant={isTabletOrMobile ? "h3" : "h2"}
              >
                {isLoading ? (
                  <Skeleton variant="text" width={150} height={45} />
                ) : carvalue ? (
                  numberWithCommas(carvalue)
                ) : (
                  "Đang cập nhật"
                )}
              </Typography>
              <Typography sx={{ color: "#FFF" }} variant="h5">
                VND
              </Typography>
            </Box>
          </Paper>
        </Grid>
        {userHidden !== user.primaryUname && (
          <Grid
            item
            md={type === "car" ? 7.2 : 12}
            lg={type === "car" ? 7.2 : 12}
            sm={type === "car" ? 7.2 : 12}
            xs={12}
          >
            <Paper
              sx={{
                backgroundColor:
                  theme.palette.mode === "dark" ? "#21262C" : "#F7F8FE",
                borderRadius: "16px",
                paddingX: isSmallScreen
                  ? "5px"
                  : reponsiveWrapText
                  ? "10px"
                  : "30px",
                paddingY: "30px",
                boxShadow: "none",
              }}
            >
              <Paper
                sx={{
                  background:
                    "linear-gradient(228deg, #7687FF 8.43%, #9FABFF 79.4%)",
                  width: "36px",
                  height: "36px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "8px",
                }}
              >
                <IconInformation />
              </Paper>
              <Box sx={{ display: "flex", alignItems: "center", mt: 3 }}>
                <Typography
                  sx={{
                    color:
                      theme.palette.mode === "dark" ? "#9CA3AF" : "#5C6386",
                    mr: 2,
                  }}
                  variant={isTabletOrMobile ? "h5" : "h3"}
                >
                  Khoảng giá cấp đơn
                </Typography>
              </Box>
              <Box className="display-space" sx={{ mt: 3 }}>
                <Typography
                  sx={{
                    color: theme.palette.mode === "dark" ? "#FFF" : "#2F353C",
                  }}
                  variant={isTabletOrMobile ? "h3" : "h2"}
                >
                  {isLoading ? (
                    <Skeleton variant="text" width={300} height={45} />
                  ) : applicationPriceRange.minPrice === null ||
                    applicationPriceRange.maxPrice === null ? (
                    "Đang cập nhật"
                  ) : (
                    `${numberWithCommas(
                      applicationPriceRange.minPrice
                    )} - ${numberWithCommas(applicationPriceRange.maxPrice)}`
                  )}
                </Typography>
                <Typography sx={{ color: "#9495B4" }} variant="h5">
                  VND
                </Typography>
              </Box>
            </Paper>
          </Grid>
        )}

        {listedPrice && (
          <Grid item md={4.8} lg={4.8} sm={4.8} xs={12}>
            <Paper
              sx={{
                backgroundColor:
                  theme.palette.mode === "dark" ? "#21262C" : "#FFF",
                borderRadius: "12px",
                paddingX: isSmallScreen
                  ? "5px"
                  : reponsiveWrapText
                  ? "10px"
                  : "30px",
                paddingY: "30px",
                boxShadow: "none",
                border:
                  theme.palette.mode === "light" ? "1px solid #E9EAF2" : "none",
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.mode === "dark" ? "#9CA3AF" : "#5C6386",
                  mt: 3,
                }}
                variant={isTabletOrMobile ? "h5" : "h3"}
              >
                Giá niêm yết
              </Typography>

              <Box className="display-space" sx={{ mt: 3 }}>
                <Typography
                  sx={{
                    color: theme.palette.mode === "dark" ? "#FFF" : "#2F353C",
                  }}
                  variant={isTabletOrMobile ? "h3" : "h2"}
                >
                  {isLoading ? (
                    <Skeleton variant="text" width={150} height={45} />
                  ) : listedPrice ? (
                    numberWithCommas(listedPrice)
                  ) : (
                    "Đang cập nhật"
                  )}
                </Typography>
                <Typography sx={{ color: "#9495B4" }} variant="h5">
                  VND
                </Typography>
              </Box>
            </Paper>
          </Grid>
        )}
        {userHidden !== user.primaryUname && listedPrice && (
          <Grid item md={7.2} lg={7.2} sm={7.2} xs={12}>
            <Paper
              sx={{
                backgroundColor:
                  theme.palette.mode === "dark" ? "#21262C" : "#FFF",
                borderRadius: "12px",
                paddingX: isSmallScreen
                  ? "5px"
                  : reponsiveWrapText
                  ? "10px"
                  : "30px",
                paddingY: "30px",
                boxShadow: "none",
                border:
                  theme.palette.mode === "light" ? "1px solid #E9EAF2" : "none",
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.mode === "dark" ? "#9CA3AF" : "#5C6386",
                  mt: 3,
                }}
                variant={isTabletOrMobile ? "h5" : "h3"}
              >
                Khoảng giá niêm yết
              </Typography>
              <Box className="display-space" sx={{ mt: 3 }}>
                <Typography
                  sx={{
                    color: theme.palette.mode === "dark" ? "#FFF" : "#2F353C",
                  }}
                  variant={isTabletOrMobile ? "h3" : "h2"}
                >
                  {isLoading ? (
                    <Skeleton variant="text" width={300} height={45} />
                  ) : listedPriceRange.minListedPrice === null ||
                    listedPriceRange.maxListedPrice === null ? (
                    "Đang cập nhật"
                  ) : (
                    `${numberWithCommas(
                      listedPriceRange.minListedPrice
                    )} - ${numberWithCommas(listedPriceRange.maxListedPrice)}`
                  )}
                </Typography>
                <Typography sx={{ color: "#9495B4" }} variant="h5">
                  VND
                </Typography>
              </Box>
            </Paper>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}

export default memo(OverView);
