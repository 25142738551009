import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { useMediaQuery } from "@mui/material";
import logo from "../../../assets/Images/Logo.png";
import logoDarkMode from "../../../assets/Images/Logo-darkmode.png";
import {
  ResultCarPrice,
  onRoadPriceResult,
  CarPriceInflation,
  carEstimatePrice,
} from "../types";
import Form from "../Form";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

export default function PermanentDrawerLeft({
  setCarPriceValue,
  setOnRoadPrice,
  setPriceVolatility,
  setCarEstimatedPrice,
  setIsLoadCarPrice,
  setError,
  setErrorVolatility,
  setErrorEstimated,
}: {
  setCarPriceValue: React.Dispatch<React.SetStateAction<ResultCarPrice>>;
  setOnRoadPrice: React.Dispatch<React.SetStateAction<onRoadPriceResult>>;
  setPriceVolatility: React.Dispatch<React.SetStateAction<CarPriceInflation[]>>;
  setCarEstimatedPrice: React.Dispatch<React.SetStateAction<carEstimatePrice>>;
  setIsLoadCarPrice: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorVolatility: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorEstimated: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const isTabletOrMobile = useMediaQuery("(max-width: 1050px)");
  const theme = useTheme();
  const navigate = useNavigate();

  const useWindowWidth = () => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    return width;
  };

  const width = useWindowWidth();

  const backToSearchCarPage = () => {
    navigate("/", { replace: true });
    localStorage.removeItem("car");
    localStorage.removeItem("truck");
    localStorage.removeItem("coach");
    localStorage.removeItem("specialized");
    localStorage.removeItem("type");
  };

  return !isTabletOrMobile ? (
    <Drawer
      sx={{
        width: 0.238 * width,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: 0.238 * width,
          boxSizing: "border-box",
          borderRight: "none",
          backgroundColor: theme.palette.mode === "dark" ? "#21262C" : "unset",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Box sx={{ paddingY: "24px", paddingX: "32px" }}>
        {theme.palette.mode === "dark" ? (
          <img
            src={logoDarkMode}
            alt="logo"
            width={136}
            height={38}
            style={{ marginBottom: "45px", cursor: "pointer" }}
            onClick={backToSearchCarPage}
          />
        ) : (
          <img
            src={logo}
            alt="logo"
            width={136}
            height={32}
            style={{ marginBottom: "45px", cursor: "pointer" }}
            onClick={backToSearchCarPage}
          />
        )}
        <Form
          setCarPriceValue={setCarPriceValue}
          setOnRoadPrice={setOnRoadPrice}
          setPriceVolatility={setPriceVolatility}
          setCarEstimatedPrice={setCarEstimatedPrice}
          setIsLoadCarPrice={setIsLoadCarPrice}
          setError={setError}
          setErrorVolatility={setErrorVolatility}
          setErrorEstimated={setErrorEstimated}
        />
      </Box>
    </Drawer>
  ) : null;
}
