import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { useMediaQuery, Button, Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Drawer from "./Drawer";
import Header from "./Header";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import OverView from "./OverView";
import Chart from "./Chart";
import Form from "./Form";
import {
  ResultCarPrice,
  onRoadPriceResult,
  CarPriceInflation,
  carEstimatePrice,
} from "./types";
import WheelAlignmentCost from "./WheelAlignmentCost";

export default function Result() {
  const [carPriceValue, setCarPriceValue] = useState<ResultCarPrice>({
    carCompany: "",
    carModel: "",
    carVersion: "",
    year: "",
    maxPrice: null,
    carValue: 0,
    minPrice: null,
    isWarning: false,
    listedPrice: null,
    minListedPrice: null,
    maxListedPrice: null,
    hanoiOnRoadPrice: null,
    generalOnRoadPrice: null,
    hcmOnRoadPrice: null,
    additions: [],
  });

  const [onRoadPrice, setOnRoadPrice] = useState<onRoadPriceResult>({
    listedPrice: 0,
    registrationFee: 0,
    registrationFeePercent: 0,
    licensePlateFee: 0,
    vehicleInspectionFee: 0,
    roadMaintenanceFee: 0,
    liabilityInsurancePremium: 0,
    totalPrice: 0,
  });

  const [priceVolatility, setPriceVolatility] = useState<CarPriceInflation[]>(
    []
  );

  const checkCondition = () => {
    if (
      !localStorage.getItem("car") &&
      !localStorage.getItem("truck") &&
      !localStorage.getItem("coach") &&
      !localStorage.getItem("specialized")
    ) {
      return false;
    }
    return true;
  };

  const [carEstimatedPrice, setCarEstimatedPrice] = useState<carEstimatePrice>({
    avgPriceSlippage: 0,
    avgPriceAfterNextYear: 0,
    percentPriceReduceAfterNextYear: 0,
    priceReduceAfterNextYear: 0,
    avgPriceAfterNext2Year: 0,
    percentPriceReduceAfterNext2Year: 0,
    priceReduceAfterNext2Year: 0,
  });

  const [isLoadCarPrice, setIsLoadCarPrice] = useState(false);
  const [, setError] = useState<boolean>(false);
  const [errorVolatility, setErrorVolatility] = useState<boolean>(false);
  const [errorCarEstimate, setErrorCarEstimate] = useState<boolean>(false);

  const navigate = useNavigate();
  const theme = useTheme();

  const isTabletOrMobile = useMediaQuery("(max-width: 1050px)");
  const type = localStorage.getItem("type");

  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/login");
    } else if (!checkCondition()) {
      navigate("/", { replace: true });
    }
  }, [navigate]);

  const onBackToLandingPage = () => {
    navigate("/", { replace: true });
    localStorage.removeItem("car");
    localStorage.removeItem("truck");
    localStorage.removeItem("coach");
    localStorage.removeItem("specialized");
    localStorage.removeItem("type");
  };

  useEffect(() => {
    if (errorVolatility) {
      setPriceVolatility([]);
    }
  }, [errorVolatility]);

  return (
    <>
      {isTabletOrMobile && (
        <Box
          sx={{
            backgroundColor:
              theme.palette.mode === "dark" ? "#21262C" : "unset",
            paddingX: "20px",
          }}
        >
          <Header />
          <Divider />
          <Box sx={{ paddingY: "20px" }}>
            <Form
              setCarPriceValue={setCarPriceValue}
              setOnRoadPrice={setOnRoadPrice}
              setPriceVolatility={setPriceVolatility}
              setCarEstimatedPrice={setCarEstimatedPrice}
              setIsLoadCarPrice={setIsLoadCarPrice}
              setError={setError}
              setErrorVolatility={setErrorVolatility}
              setErrorEstimated={setErrorCarEstimate}
            />
          </Box>
        </Box>
      )}
      <Box sx={{ display: "flex" }}>
        <Drawer
          setPriceVolatility={setPriceVolatility}
          setCarPriceValue={setCarPriceValue}
          setOnRoadPrice={setOnRoadPrice}
          setCarEstimatedPrice={setCarEstimatedPrice}
          setIsLoadCarPrice={setIsLoadCarPrice}
          setError={setError}
          setErrorVolatility={setErrorVolatility}
          setErrorEstimated={setErrorCarEstimate}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            bgcolor: theme.palette.mode === "dark" ? "#121212" : "#F7F8FE",
            minHeight: "100vh",
            width: "100%",
            px: "40px",
          }}
        >
          {!isTabletOrMobile && <Header />}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              mt: isTabletOrMobile ? 2 : 0,
            }}
          >
            <Button
              sx={{
                textTransform: "unset",
                fontSize: "20px",
                border: "none",
                color: theme.palette.mode === "dark" ? "#ffff" : "#21252C",
                ":hover": {
                  border: "none",
                  backgroundColor: "transparent",
                },
                textAlign: "left",
              }}
              onClick={onBackToLandingPage}
              variant="outlined"
              startIcon={<ArrowBackIcon />}
            >
              Giá trị xe
            </Button>
          </Box>
          <OverView
            isLoading={isLoadCarPrice}
            carvalue={carPriceValue.carValue}
            applicationPriceRange={{
              minPrice: carPriceValue.minPrice,
              maxPrice: carPriceValue.maxPrice,
            }}
            listedPrice={carPriceValue.listedPrice}
            listedPriceRange={{
              minListedPrice: carPriceValue.minListedPrice,
              maxListedPrice: carPriceValue.maxListedPrice,
            }}
          />
          {type === "car" && (
            <WheelAlignmentCost
              onRoadPrice={onRoadPrice}
              setOnRoadPrice={setOnRoadPrice}
            />
          )}
          {type === "car" && (
            <Chart
              priceVolatility={priceVolatility}
              carEstimatePrice={carEstimatedPrice}
              error={errorVolatility}
              errorEstimated={errorCarEstimate}
            />
          )}
        </Box>
      </Box>
    </>
  );
}
